import Vue from "vue";
import gsap from "gsap";

export default {
  components: {},
  props: {},
  data() {
    return {
      ShowDialogOverrideExperience: false,
      spaceEditing: null,
      selectedExperience: null,
      selectedPlaylist: this.$store.state.setup.playlist,
    };
  },
  watch: {
    selectedPlaylist(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updatePlaylist();
      }
    },
  },
  methods: {
    EnterRoute(route) {
      this.$store.commit("StartLoading");
      this.$router.push(route)
    },
    EnterExperience(spaceSlug) {
      this.$store.commit(
        "user/setMenuOffsetTop",
        this.$parent.$refs["mainSection"].$el.scrollTop
      );
      this.$store.commit("StartLoading");
      this.$router.push(spaceSlug).catch((err) => {
        console.error("Failed to navigate:", err);
        this.$store.commit("StopLoading");
        alert("Failed to enter experience. Please try again.");
      });
    },
    roomsInSection(section) {
      return this.playlist.filter((x) => x.space.section.id == section.id);
    },
    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = "translateY(-50px)";
    },
    enter(el, done) {
      gsap.to(el, {
        opacity: 1,
        y: 0,
        duration: 0.8,
        onComplete: done,
        delay: el.dataset.index * 0.1,
      });
    },
    leave(el, done) {
      gsap.to(el, {
        opacity: 0,
        x: -20,
        duration: 0.05,
        onComplete: done,
      });
    },
    ExperienceCompleted(experienceId) {
      return this.completedExperencies.includes(experienceId);
    },
  },
  computed: {
    gridCards() {
      const event = this.$store.state.setup.event;
      const eventCards = [
        {
          label: Vue.prototype.i18n.t("event.calendar"),
          route: "/calendar",
          icon: "FaCalendar",
          enabled: event.calendar?.enabled,
        },
        {
          label: Vue.prototype.i18n.t("event.map"),
          route: "/map",
          icon: "FaMap",
          enabled: event.maps?.enabled,
        },
        {
          label: Vue.prototype.i18n.t("event.booker"),
          route: "/booker",
          icon: "FaFutbol",
          enabled: event.booker?.enabled,
        },
        {
          label: Vue.prototype.i18n.t("event.photofeed"),
          route: "/photofeed",
          icon: "FaCamera",
          enabled: event.photofeed?.enabled,
        },
        {
          label: Vue.prototype.i18n.t("event.favorites"),
          route: "/favorites",
          icon: "FaStar",
          enabled: event.favoriteGallery?.enabled,
        },
      ];

      const filteredEventCards = eventCards.filter((card) => card.enabled);

      const experienceCards = this.showGrid ? this.selectedPlaylist.content
        .filter(item => item.experience && item.hideInMenu === false)
        .map(item => ({
          label: item.experience.title,
          icon: item.experience.category.icon,
          route: item.space.slug
        })) : [];

      return [...filteredEventCards, ...experienceCards];
    },
    showGrid() {
      return this.selectedPlaylist.experiencesLayout === "grid"
    },
    playlist() {
      return this.$store.getters.menuItems;
    },
    sections() {
      return this.$store.getters.menuSections;
    },
    completedExperencies() {
      return this.$store.state.user.achievements.map((x) => x.experience);
    },
  },
  async mounted() {
    this.$parent.$refs["mainSection"].$el.scrollTo(
      0,
      this.$store.state.user.menuOffsetTop
    );

    if (this.$store.state.routeFrom.path != "/") {
      this.$socket.client.emit(
        "player:JoinSpace",
        { spaceSlug: "menu" },
        (response) => {
          if (response.status) {
            console.log("Server allowed join");
          } else {
            console.warn("Server did not allow joining");
          }
        }
      );
    }

    await this.$store.dispatch("user/GetAchievements");
    this.$store.dispatch("user/NeedsToRegister");
  },
};
