var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"menu",class:{ 'has-text-white': _vm.$parent.$parent.hasMobileBackground },style:(_vm.$parent.$parent.mobileBackground)},[(_vm.$store.state.setup.description)?_c('section',{staticClass:"home-menu-banner section p-3 is-size-7"},[_vm._v(" "+_vm._s(_vm.$store.state.setup.description)+" ")]):_vm._e(),_c('transition-group',{staticClass:"grid-module",attrs:{"appear":"","tag":"div"},on:{"before-enter":_vm.beforeEnter,"enter":_vm.enter,"leave":_vm.leave}},_vm._l((_vm.gridCards),function(card,i){return _c('div',{key:card.label,staticClass:"grid-item",attrs:{"data-index":i},on:{"click":function($event){return _vm.EnterRoute(card.route)}}},[_c('div',[_c('d-icon',{staticClass:"has-text-primary",attrs:{"icon":card.icon,"size":"is-medium"}}),_c('label',{staticClass:"label has-text-black-bis"},[_vm._v(_vm._s(card.label))])],1)])}),0),(!_vm.showGrid)?_c('transition-group',{staticClass:"section p-0 pt-5",attrs:{"appear":"","tag":"section"},on:{"before-enter":_vm.beforeEnter,"enter":_vm.enter,"leave":_vm.leave}},_vm._l((_vm.sections),function(section,i){return _c('div',{key:'cat' + i,staticClass:"experience-section p-4 mb-5",style:({ '--section-color': section.color }),attrs:{"data-index":i}},[(section.title)?_c('div',{staticClass:"section-title is-size-5 mb-4"},[_vm._v(" "+_vm._s(section.title)+" ")]):_vm._e(),_vm._l((_vm.roomsInSection(section)),function(item){return _c('div',{key:item.space.id,ref:item.space.slug,refInFor:true,staticClass:"experience-button my-2",class:[
          {
            'is-checked': _vm.ExperienceCompleted(item.experience.id),
            'is-low': item.space.hierarchy == 'low',
          },
          'blocktype_' + item.experience.component[0].blockType,
        ],on:{"click":function($event){return _vm.EnterExperience(item.space.slug)}}},[_c('div',{staticClass:"circle"},[(item.experience.category.icon)?_c('d-icon',{attrs:{"icon":item.experience.category.icon,"size":"is-small"}}):_vm._e()],1),_c('div',{staticClass:"experience-title py-2 px-3"},[_vm._v(" "+_vm._s(item.experience.title)+" ")]),(item.space.alphanumeric)?_c('div',{staticClass:"space p-2 has-text-weight-bold"},[_vm._v(" "+_vm._s(item.space.alphanumeric)+" ")]):_vm._e()])})],2)}),0):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }